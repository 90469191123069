import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { updateDoc, getDoc, doc } from "firebase/firestore";
import { db } from "../firebase.config";
import useAdminAuth from "../hooks/useAdminAuth";

import ContentContainer from "../layout/ContentContainer";

function EditPost() {
  const [post, setPost] = useState({
    title: "",
    content: "",
  });

  const isAdmin = useAdminAuth();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      const docRef = doc(db, "posts", params.storyId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPost(docSnap.data());
      }
    };
    fetchPost();
  }, []);

  const onChangeTitleHandler = e => {
    setPost(prevState => ({
      ...prevState,
      title: e.target.value,
    }));
  };

  const onChangeContentHandler = e => {
    setPost(prevState => ({
      ...prevState,
      content: e,
    }));
  };

  const onSubmit = async e => {
    e.preventDefault();
    const docRef = doc(db, "posts", params.storyId);
    await updateDoc(docRef, post);
    toast.success("Storka aktualizována...", {
      style: {
        border: "4px solid #663366",
        padding: "16px",
        color: "#663366",
        fontSize: "1.25rem",
        top: "55px",
      },
      iconTheme: {
        primary: "#663366",
        secondary: "#FFFAEE",
      },
    });

    navigate(`/blog/${params.storyId}`);
  };

  if (!isAdmin) return;

  return (
    post && (
      <ContentContainer>
        <form className="editpost" onSubmit={onSubmit}>
          <h2 className="editpost__heading">Upravit Storku</h2>
          <button className="editpost__button">upravit</button>
          <input
            className="editpost__input"
            type="text"
            placeholder="nadpis - všechno malým"
            id="edit-title"
            value={post.title}
            onChange={onChangeTitleHandler}
            required
          />
          <ReactQuill
            className="editpost__editor"
            theme="snow"
            value={post.content}
            onChange={onChangeContentHandler}
            required
          />
        </form>
      </ContentContainer>
    )
  );
}

export default EditPost;
