import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import Header from './layout/Header'
import AboutMe from './pages/AboutMe'
import Services from './pages/Services'
import Blog from './pages/Blog'
import Courses from './pages/Courses'
import Course from './pages/Course'
import Post from './pages/Post'
import Login from './pages/Login'
import AddPost from './pages/AddPost'
import AddCourse from './pages/AddCourse'
import EditPost from './pages/EditPost'
import EditCourse from './pages/EditCourse'
import Socionika from './pages/Socionika'
import Contact from './pages/Contact'

import Home from './pages/Home'

function App() {
  return (
    <>
      <Router>
        <Header></Header>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/aboutme' element={<AboutMe />} />
          <Route path='/services' element={<Services />} />
          <Route path='/socionika' element={<Socionika />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/:storyId' element={<Post />} />
          <Route path='/courses' element={<Courses />} />
          <Route path='/courses/:courseId' element={<Course />} />
          <Route path='/login' element={<Login />} />
          <Route path='/addpost' element={<AddPost />} />
          <Route path='/addcourse' element={<AddCourse />} />
          <Route path='/editpost/:storyId' element={<EditPost />} />
          <Route path='/editcourse/:courseId' element={<EditCourse />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </Router>
      <Toaster position='bottom-left' />
    </>
  )
}

export default App
