import { useState } from "react";

import { Link, Navigate, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import toast from "react-hot-toast";
import ContentContainer from "../layout/ContentContainer";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;
  const navigate = useNavigate();

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const auth = getAuth();

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        navigate("/addpost");
        toast.success("Přihlášená, dobrý den... :)", {
          style: {
            border: "4px solid #663366",
            padding: "16px",
            color: "#663366",
            fontSize: "1.25rem",
            top: "55px",
          },
          iconTheme: {
            primary: "#663366",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      alert("Špatné jméno nebo heslo.");
    }
  };
  return (
    <ContentContainer className="login">
      <form onSubmit={onSubmit} className="login__form">
        <label className="login__label" htmlFor="email">
          email
        </label>
        <input
          className="login__input"
          type="email"
          value={email}
          id="email"
          onChange={onChange}
        />
        <label className="login__label" htmlFor="password">
          heslo
        </label>
        <input
          className="login__input"
          type="password"
          value={password}
          id="password"
          onChange={onChange}
        ></input>
        <button className="login__button" type="submit">
          přihlásit
        </button>
      </form>
    </ContentContainer>
  );
}

export default Login;
