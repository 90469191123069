import ContentContainer from "../layout/ContentContainer";
import { MdPsychology } from "react-icons/md";
import { FaRegSmile } from "react-icons/fa";
import Balzac from "../assets/images/types/balzac.png";
import Dostojevski from "../assets/images/types/dostojevski.jpg";
import Dreiser from "../assets/images/types/dreiser.jpg";
import Dumas from "../assets/images/types/dumas.png";
import Gaben from "../assets/images/types/gaben.png";
import Hamlet from "../assets/images/types/hamlet.png";
import Hugo from "../assets/images/types/hugo.png";
import Huxley from "../assets/images/types/huxley.jpg";
import Jesenin from "../assets/images/types/jesenin.png";
import London from "../assets/images/types/london.png";
import Maxim from "../assets/images/types/maxim.png";
import Napoleon from "../assets/images/types/napoleon.png";
import Quijote from "../assets/images/types/quijote.png";
import Robesspierre from "../assets/images/types/robesspierre.png";
import Stirlitz from "../assets/images/types/stirlitz.png";
import Zukov from "../assets/images/types/zukov.png";

function Socionika() {
  const lineOne = [
    {
      key: "quijote",
      url: Quijote,
      name: "DON QUIJOTE",
      p: "vynálezce, inovátor",
    },
    {
      key: "dumas",
      url: Dumas,
      name: "DUMAS",
      p: "usmiřovatel, pečovatel",
    },
    {
      key: "robespierre",
      url: Robesspierre,
      name: "ROBESPIERRE",
      p: "analytik, vědec",
    },
    {
      key: "hugo",
      url: Hugo,
      name: "HUGO",
      p: "nadšenec",
    },
  ];

  const lineTwo = [
    {
      key: "zukov",
      url: Zukov,
      name: "ŽUKOV",
      p: "vůdce, stratég",
    },
    {
      key: "jesenin",
      url: Jesenin,
      name: "JESENIN",
      p: "romantik, lyrik",
    },
    {
      key: "maxim",
      url: Maxim,
      name: "MAXIM",
      p: "inspektor, pragmatik",
    },
    {
      key: "hamlet",
      url: Hamlet,
      name: "HAMLET",
      p: "herec, učitel",
    },
  ];

  const lineThree = [
    {
      key: "napoleon",
      url: Napoleon,
      name: "NAPOLEON",
      p: "politik, diplomat",
    },
    {
      key: "london",
      url: London,
      name: "LONDON",
      p: "podnikatel, průkopník",
    },
    {
      key: "dreiser",
      url: Dreiser,
      name: "DREISER",
      p: "strážce, ochránce",
    },
    {
      key: "balzac",
      url: Balzac,
      name: "BALZAC",
      p: "kritik, pozorovatel",
    },
  ];

  const lineFour = [
    {
      key: "gaben",
      url: Gaben,
      name: "GABÉN",
      p: "mistr, řemeslník",
    },
    {
      key: "huxley",
      url: Huxley,
      name: "HUXLEY",
      p: "motivátor, reportér",
    },
    {
      key: "stierlitz",
      url: Stirlitz,
      name: "STIERLITZ",
      p: "ředitel, organizátor",
    },
    {
      key: "dostojevki",
      url: Dostojevski,
      name: "DOSTOJEVSKI",
      p: "humanista, sociolog",
    },
  ];

  return (
    <ContentContainer>
      <div className="socionika__image"></div>
      <div className="socionika">
        <div className="socionika__paragraphs">
          <p className="socionika__paragraph">
            Socionika je doslova darem v sebepoznání. Znát svůj socionický typ
            vám pomůže lépe pochopit své postoje k situacím a také své názory.
            Možná zjistíte, proč vás někdo irituje a tím pádem ho přijmete a už
            vám vadit nebude.{" "}
          </p>
          <p className="socionika__paragraph">
            Je to ten nejlepší nástroj v sebepoznání, jaký jsem poznala a jediné
            čeho lituju je, že jsem socioniku neznala už před dvaceti lety.{" "}
          </p>
          <div className="socionika__list">
            <span> V životě vám může pomoct:</span>
            <ul aria-label="V životě vám může pomoct:">
              <li>
                <MdPsychology />v lepších vztazích doma i v práci
              </li>
              <li>
                <MdPsychology />
                ve výběru partnera
              </li>
              <li>
                <MdPsychology />
                ve výběru školy, práce i kroužků
              </li>
              <li>
                <MdPsychology />v cestě k podnikání
              </li>
              <li>
                <MdPsychology />v sebelásce a sebepřijetí
              </li>
              <li>
                <MdPsychology />v přijetí druhých lidí
              </li>
              <li>
                <MdPsychology />v nalezení silných stránek
              </li>
              <li>
                <MdPsychology />v nalezení sebe
              </li>
            </ul>
          </div>
          <div className="socionika__types">
            <h4 className="socionika__types-heading">
              Socionických typů je 16 a dělí se na 4 kvadry
            </h4>
            <div className="socionika__types-groups">
              <div className="socionika__types-group">
                <p className="socionika__types-group-description">
                  1. Kvadra miluje nové nápady a inovace, většinou přichází s
                  tím, co si ostatní ještě ani nedovedou představit.
                </p>
                <div className="socionika__types-group-items">
                  {lineOne.map(item => (
                    <div className="socionika__types-group-item" key={item.key}>
                      <div
                        className="socionika__types-group-item-img"
                        style={{
                          backgroundImage: `url('${item.url}')`,
                        }}
                      ></div>
                      <div className="socionika__types-group-item-text">
                        <div className="socionika__types-group-item-name">
                          {item.name}
                        </div>
                        <p className="socionika__types-group-item-paragraph">
                          {item.p}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="socionika__types-group">
                <p className="socionika__types-group-description">
                  2. Kvadra je skupina bojovníků za spravedlnost a správný
                  systém. Vše, co dělají, musí dávat smysl.
                </p>
                <div className="socionika__types-group-items">
                  {lineTwo.map(item => (
                    <div className="socionika__types-group-item" key={item.key}>
                      <div
                        className="socionika__types-group-item-img"
                        style={{
                          backgroundImage: `url('${item.url}')`,
                        }}
                      ></div>
                      <div className="socionika__types-group-item-text">
                        <div className="socionika__types-group-item-name">
                          {item.name}
                        </div>
                        <p className="socionika__types-group-item-paragraph">
                          {item.p}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="socionika__types-group">
                <p className="socionika__types-group-description">
                  3. Kvadra je skupina pracantů, kteří milují výsledky a
                  efektivitu
                </p>
                <div className="socionika__types-group-items">
                  {lineThree.map(item => (
                    <div className="socionika__types-group-item" key={item.key}>
                      <div
                        className="socionika__types-group-item-img"
                        style={{
                          backgroundImage: `url('${item.url}')`,
                        }}
                      ></div>
                      <div className="socionika__types-group-item-text">
                        <div className="socionika__types-group-item-name">
                          {item.name}
                        </div>
                        <p className="socionika__types-group-item-paragraph">
                          {item.p}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="socionika__types-group">
                <p className="socionika__types-group-description">
                  4. Kvadra zlepšuje a velmi dobře přijímá to, co je ve
                  společnosti již dané a zaběhnuté.
                </p>
                <div className="socionika__types-group-items">
                  {lineFour.map(item => (
                    <div className="socionika__types-group-item" key={item.key}>
                      <div
                        className="socionika__types-group-item-img"
                        style={{
                          backgroundImage: `url('${item.url}')`,
                        }}
                      ></div>
                      <div className="socionika__types-group-item-text">
                        <div className="socionika__types-group-item-name">
                          {item.name}
                        </div>
                        <p className="socionika__types-group-item-paragraph">
                          {item.p}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="socionika__paragraph">
          Velmi zajímavé je také to, že každý typ má s dalšími 15 typy
          specifické vztahy, jako jsou např. vztah{" "}
          <strong>rodinný, konfliktní,</strong> nebo třeba{" "}
          <strong>duální.</strong> Ten poslední je ideální pro vztah muž x žena,
          takže vás socionika nasměruje i na to, na jaký typ se máte zaměřit,
          pokud hledáte partnera pro život.
        </p>
      </div>
      <div className="aboutme">
        <div className="aboutme__heading">
          <h2>Příběh ze života o tom, jak mi socionika pomáhá</h2>
        </div>
        <div className="aboutme__story-paragraphs">
          <p className="aboutme__story-paragraph">
            <strong>
              Jsem typ DREISER a mám 4 děti, Nejstarší dcera je ŽUKOV, syn je
              ROBESPIERRE, mladší dcera je DUMAS a nejmladší dcera je HAMLET.
            </strong>
          </p>
          <p className="aboutme__story-paragraph">
            <strong>ŽUKOV</strong> je velmi samostatný typ. Spoustu věcí moje
            dcera zvládne sama a nepotřebuje k tomu žádné lidi. Jenže tak jak to
            má ona, to vyžaduje také od druhých a to někdy působí až sobecky,
            protože ŽUKOV si například ze stolu odnese jen své nádobí (ostatní
            mají přece taky ruce). Já jako DREISER, který je typ etický a
            obětavý (někdy až moc), jsem měla někdy pocit, že je moje dcera
            sobecká a nemyslí na druhé. Říkala jsem si, jak ji už mám jinak
            vést, aby byla obětavější…
          </p>
          <p className="aboutme__story-paragraph">
            <strong>
              Chyba lávky. Od té doby, co znám její typ, jsem si uvědomila, jak
              moc mi pomohla, protože její dva mladší sourozenci (dvojčata) ji
              vždy měli za vzor a snažili se být více samostatní, než jim jejich
              vlastní typ dovolí. Díky Bohu za ŽUKOVA v rodině <FaRegSmile />{" "}
              naučila dokonce i mne myslet více na sebe a neobětovat se tolik
              pro druhé.{" "}
            </strong>
          </p>
          <p className="aboutme__story-paragraph">
            <strong>ROBESPIERRE</strong> je analytický logik. Vede dlouhé
            diskuze, protože ho navíc jeho intuice možností, na druhé
            nejsilnější stránce nutí vidět věci v širších souvislostech. Můj syn
            občas něco nesplnil, jako každé dítě, ale stávalo se mu to častěji,
            než jeho sourozencům. Jenomže, když jsem mu to vyčetla, začal se
            jakoby „vymlouvat“ proč to nemohl udělat. Strašně mě tím vytáčel a
            našla jsem si pak alespoň legrační slovenský výraz - len samé
            výhovorky a výhovorky, abych situaci nějak odlehčila.
          </p>
          <p className="aboutme__story-paragraph">
            Jenomže to jsem netušila, že to tak v jeho socionickém typu mají
            všichni ROBESPIEROVÉ. Prostě jim do splnění úkolu přijde tolik
            myšlenek a tolik možností, co ještě mají udělat, že na to pak
            zkrátka zapomenou. Když jsem na socionickém víkendu slyšela mluvit
            jiné ROBÍKY (jak tomuto typu v Socionu říkáme) a uvědomila jsem si,
            že je to vlastně mrzí, že to mnohdy nemohou dodržet, ukápla mi slza
            při vzpomínce na syna. Přijela jsem domů, řekla jsem mu, že ho už
            chápu a je mi líto, že jsem ho stále obviňovala z výmluv. Objal mě,
            řekl, že se nezlobí, ale od té doby se mu plnit své úkoly daří
            daleko lépe. Dokonce pro mne dělá i věci navíc, které mu neříkám.
          </p>
          <p className="aboutme__story-paragraph">
            <strong>
              A důvod? Přijetí. Přijala jsem ho díky socionice i s tímto pro mě
              v domácnosti nepříjemným nedostatkem (protože DREISER přece
              potřebuje, aby to všechno doma klapalo na 150% <FaRegSmile />) a
              tím to mezi námi přestalo tak drhnout a máme větší prostor na náš
              společný smysl pro humor.
            </strong>
          </p>
          <p className="aboutme__story-paragraph">
            <strong>DUMAS</strong> je senzorický etik, stejně jako já. Jenomže
            jeho senzorika je vnitřní, to znamená, že vnímá více pocitově, než
            já - hmotně. Má dcera DUMASKA byla jako malé dítě takové to
            “sluníčkové a vysmáté děcko“. V době kdy závodně tančila si porotu
            získala nejen talentem, ale také její pozitivitou. Ale když přišla
            do puberty začala mít negativní pohled na věci kolem sebe. Trápilo
            mě to a vždycky, když ji něco štvalo, jsem jí řekla, ať to prostě
            pustí z hlavy. Jenomže tento typ, obzvlášť v tomto období má opravdu
            silné pocity a některé věci ji zkrátka nejdou jen tak pustit z
            hlavy. Dcera si našla jiný ventil - posilovnu a od té doby je i její
            psychika lepší a já jsem si díky socionice uvědomila, že nemusím
            vždycky a za každou cenu dávat dětem radu na vyřešení problémů a tak
            jsem se začala snažit její pocity jen více chápat.
          </p>
          <p className="aboutme__story-paragraph">
            <strong>
              Když jsem k tomuto uvědomění došla a změnila to v hlavě, dcera ke
              mně po delší době, kdy to naposled udělala přišla a sama od sebe
              mě objala. Jen tak a z ničeho nic a já vím, že energie ještě
              většího pochopení a přijetí z mé strany se k ní už dostala.
            </strong>
          </p>
        </div>{" "}
        <div className="aboutme__heading">
          <h2>A co jsem na sobě změnila já po zjištění, že jsem DREISER?</h2>
        </div>
        <div className="socionika__list  socionika__list-long">
          <span>Dřív to bylo takto:</span>
          <ul aria-label="Dřív to bylo takto:">
            <li>
              <MdPsychology />
              Přestože jsem na sobě pracovala čím dál vědoměji asi dvacet let,
              než jsem znala svůj socionický typ nechápala jsem, jak je možné,
              že vidím jaké morální pochybení společnost ničí a většina lidí mě
              vůbec nechápe.
            </li>
            <li>
              <MdPsychology />
              Ptala jsem se sama sebe, proč vždycky zvládnu a vydržím více, než
              většina lidí kolem mně a jestli tím pádem nezacházím za své
              hranice, přestože to tak necítím.
            </li>
            <li>
              <MdPsychology />V minulosti jsem si vyčítala, že jsem se někdy až
              tak moc zastávala svých blízkých, že mně samotné to škodilo.
            </li>
            <li>
              <MdPsychology />
              Ty nezdravé vztahy a situace z nich vyplývající, co byly kolem mne
              a byly pro mě nepochopitelně zametány pod koberec, měly pro mne
              jasnou a viditelnou příčinu, takže jsem se neustále divila, proč
              si to ti lidé upřímně nevyříkají, když je to přece tak zřejmé.
            </li>
          </ul>
        </div>
        <div className="socionika__list socionika__list-long">
          <span>A jak to je teď:</span>
          <ul aria-label="A teď:">
            <li>
              <MdPsychology />
              Americký novinář Theodore Dreiser, podle kterého je můj socionický
              typ pojmenován, poukazoval právě na špatnou morálku ve
              společnosti.{" "}
              <strong>
                Tím že to vím, jsem v sobě našla jakýsi klid a přijetí, že
                opravdu nejsem divná
              </strong>{" "}
              <FaRegSmile />
            </li>
            <li>
              <MdPsychology />
              Dreiser vydrží hodně a navíc disponuje i trpělivostí, dnes když to
              vím, využívám toho vědomě a už se neptám, jestli je něco za moji
              hranici.{" "}
              <strong>
                Prostě vám budu trpělivě naslouchat a hledat ve vašich
                programech v mysli do hloubky jednu příčinu problémů za druhou,
                až se dobereme k pochopení
              </strong>{" "}
              <FaRegSmile />
            </li>
            <li>
              <MdPsychology />
              Dreiser jako strážce a ochránce si opravdu musí dávat pozor na to,
              ať to s tím pečováním o druhé nepřehání.{" "}
              <strong>Křivdy druhých na sebe už nikdy brát nebudu.</strong>{" "}
              <FaRegSmile />
            </li>
            <li>
              <MdPsychology />
              Vidět příčiny problémů ve vztazích beru už jen jako můj dar a
              nepozastavuji se nad tím, že si lidi vzájemně, ale i sami sobě
              škodí.{" "}
              <strong>
                Ty co chtějí ode mne poradit a jsou otevřeni tomu na sobě
                pracovat, učím správné upřímné komunikaci i vnitřnímu dialogu a
                tady uplatňuji své silné stránky smysluplně.
              </strong>{" "}
              <FaRegSmile />
            </li>
            <li>
              <MdPsychology />
              Velkou úlevou bylo uvědomění, že otázkou budoucnosti se Dreiser
              zabývá víc, než většina typů a výzva je přestat si tyto otázky
              klást.{" "}
              <strong>
                Daří se mi díky tomu, že to vím si takové otázky nepokládat a
                tím pádem se nedostávám tolik do stresu a víc se mi v životě
                daří.
              </strong>{" "}
              <FaRegSmile />
            </li>
          </ul>
        </div>
        <p className="aboutme__story-statement">
          <strong>
            Je toho mnoho, jak mi socionika v životě pomáhá, ale nejlepší bude,
            když si to vyzkoušíte sami na sobě.
          </strong>
        </p>
      </div>
    </ContentContainer>
  );
}

export default Socionika;
