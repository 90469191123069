import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase.config";

import useAdminAuth from "../hooks/useAdminAuth";
import toast from "react-hot-toast";

import ContentContainer from "../layout/ContentContainer";

function Post() {
  const [post, setPost] = useState();
  const [postId, setPostId] = useState();
  const isAdmin = useAdminAuth();

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      const docRef = doc(db, "posts", params.storyId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPost(docSnap.data());
        setPostId(params.storyId);
      }
    };
    fetchPost();
  }, [params.storyId]);

  const handleEdit = e => {
    e.preventDefault();
    navigate(`/editpost/${params.storyId}`);
  };

  const handleDelete = async e => {
    e.preventDefault();
    if (window.confirm(`Opravdu si přeješ smazat ${post.title}???`)) {
      const docRef = doc(db, "posts", postId);
      await deleteDoc(docRef, post);
      toast.success("Storka smazána...", {
        style: {
          border: "4px solid #663366",
          padding: "16px",
          color: "#663366",
          fontSize: "1.25rem",
          top: "55px",
        },
        iconTheme: {
          primary: "#663366",
          secondary: "#FFFAEE",
        },
      });

      navigate(`/blog`);
    }
  };

  return (
    <ContentContainer>
      {post && (
        <>
          <div
            className="post__image"
            style={{ backgroundImage: `url(${post.imgUrl})` }}
          ></div>
          <div className="post">
            <h2 className="post__heading">{post.title}</h2>
            {isAdmin && (
              <button className="post__edit-btn" onClick={handleEdit}>
                upravit
              </button>
            )}
            {isAdmin && (
              <button className="post__delete-btn" onClick={handleDelete}>
                smazat
              </button>
            )}
            <div
              className="post__content"
              dangerouslySetInnerHTML={{ __html: post.content }}
            ></div>
          </div>
        </>
      )}
    </ContentContainer>
  );
}

export default Post;
