import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getDoc, doc, deleteDoc } from 'firebase/firestore'
import { db } from '../firebase.config'

import useAdminAuth from '../hooks/useAdminAuth'
import toast from 'react-hot-toast'

import ContentContainer from '../layout/ContentContainer'

function Post() {
  const [course, setCourse] = useState()
  const [courseId, setCourseId] = useState()
  const isAdmin = useAdminAuth()

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchPost = async () => {
      const docRef = doc(db, 'courses', params.courseId)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        setCourse(docSnap.data())
        setCourseId(params.courseId)
      }
    }
    fetchPost()
  }, [params.courseId])

  const handleEdit = e => {
    e.preventDefault()
    navigate(`/editcourse/${params.courseId}`)
  }

  const handleDelete = async e => {
    e.preventDefault()
    if (window.confirm(`Opravdu si přeješ smazat ${course.title}???`)) {
      const docRef = doc(db, 'courses', courseId)
      await deleteDoc(docRef, course)
      toast.success('Kurz smazán...', {
        style: {
          border: '4px solid #663366',
          padding: '16px',
          color: '#663366',
          fontSize: '1.25rem',
          top: '55px',
        },
        iconTheme: {
          primary: '#663366',
          secondary: '#FFFAEE',
        },
      })

      navigate(`/courses`)
    }
  }

  const createMarkup = html => ({ __html: html })

  return (
    <ContentContainer>
      {course && (
        <div className='course'>
          {isAdmin && (
            <button
              className='post__edit-btn course__btns'
              onClick={handleEdit}
            >
              upravit
            </button>
          )}
          {isAdmin && (
            <button
              className='post__delete-btn course__btns'
              onClick={handleDelete}
            >
              smazat
            </button>
          )}
          <div
            className='post__content course__content'
            dangerouslySetInnerHTML={createMarkup(course.content)}
          ></div>
        </div>
      )}
    </ContentContainer>
  )
}

export default Post
