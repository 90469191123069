import React from "react";
import ContentContainer from "../layout/ContentContainer";

function Home() {
  return (
    <ContentContainer className="home">
      <section className="home__hero home__section">
        <div className="home__hero-credit">
          Photo by{" "}
          <a href="https://unsplash.com/@iamromankraft?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Roman Kraft{" "}
          </a>
          on{" "}
          <a href="https://unsplash.com/photos/0EVKn3-5JSU?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Unsplash
          </a>
        </div>
        <div className="home__hero-testimonial">
          <h1 className="home__hero-heading">
            Zuzana Jasníkova - socionika, psychologie, životní styl, blog...
          </h1>
          <p className="home__hero-testimonial--paragraph">
            Mými dary je být pevnou oporou a umět najít správná řešení. Pracuji
            plně skrze tyto dary a intuici.
          </p>
          <h2 className="home__hero-testimonial--subheading">
            Všechno své konání podřizuji lásce a důvěře v kompetenci každé duše.
          </h2>
          <p className="home__hero-testimonial--intro">
            Nevíš jak dál? Chceš se pohnout z místa a nevíš jak? Zoufáš si nad
            těžkostí, kterou ti život přináší? Chceš to změnit?{" "}
            <span>Tak přesně pro tebe jsem tady. </span>
          </p>
        </div>
      </section>
      <section className="home__headline">
        <h2 className="home__headline-statement">
          Mým posláním je být <span>průvodcem</span>
        </h2>
        <div className="home__headline-photo"></div>
      </section>
      <section className="home__photo">
        {/* <div className="home__photo-paragraph">
          <p className="home__photo-paragraph--text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Est quam
            sit facilis perferendis. Fugit harum, beatae, amet illo totam
            quaerat odit eos culpa architecto illum nihil obcaecati dolor rerum
            commodi. Iusto ut quos beatae delectus minima ad amet sint quis? Eos
            expedita quasi esse eligendi recusandae nulla laboriosam cum sed
            tempore aut mollitia nihil, rerum suscipit laborum nobis quaerat
            fugit.
          </p>
        </div> */}
      </section>
    </ContentContainer>
  );
}

export default Home;
