import { useState } from 'react'
import { Link } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import useAdminAuth from '../hooks/useAdminAuth'
import toast from 'react-hot-toast'

import { Divide as Hamburger } from 'hamburger-react'

export default function Header() {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const isAdmin = useAdminAuth()
  const auth = getAuth()

  const toggleMenu = e => {
    setIsOpenMenu(prevState => !prevState)
  }

  const logoutHandler = e => {
    auth.signOut()
    toast.success('Odhlášená, tak zase příště... :)', {
      style: {
        border: '4px solid #663366',
        padding: '16px',
        color: '#663366',
        fontSize: '1.25rem',
        top: '55px',
      },
      iconTheme: {
        primary: '#663366',
        secondary: '#FFFAEE',
      },
    })
  }

  return (
    <header className='header'>
      <div className='header__logo'>
        <h1 className='header__logo-text'>Zuzana Jasníková</h1>
      </div>
      <button className='header__burger' onClick={toggleMenu} aria-label='menu'>
        <Hamburger toggled={isOpenMenu} />
      </button>
      <nav className={`header__navbar ${isOpenMenu && 'header-open'}`}>
        <ul className='header__items'>
          <li className='header__item'>
            <Link to='/' className='header__link' onClick={toggleMenu}>
              domů
            </Link>
          </li>
          <li className='header__item'>
            <Link to='/aboutme' className='header__link' onClick={toggleMenu}>
              o mně
            </Link>
          </li>
          <li className='header__item'>
            <Link to='/services' className='header__link' onClick={toggleMenu}>
              služby
            </Link>
          </li>
          <li className='header__item'>
            <Link to='/blog' className='header__link' onClick={toggleMenu}>
              blog
            </Link>
          </li>
          <li className='header__item'>
            <Link to='/courses' className='header__link' onClick={toggleMenu}>
              kurzy a akce
            </Link>
          </li>
          <li className='header__item'>
            <Link to='/socionika' className='header__link' onClick={toggleMenu}>
              socionika
            </Link>
          </li>
          <li className='header__item'>
            <Link to='/contact' className='header__link' onClick={toggleMenu}>
              kontakt
            </Link>
          </li>
          {isAdmin && (
            <li className='header__item'>
              <Link to='/addpost' className='header__link'>
                přidat storku
              </Link>
            </li>
          )}
          {isAdmin && (
            <li className='header__item'>
              <Link to='/addcourse' className='header__link'>
                přidat kurz
              </Link>
            </li>
          )}
          {isAdmin && (
            <li className='header__item'>
              <Link to='/' className='header__link' onClick={logoutHandler}>
                odhlásit
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </header>
  )
}
