import { BsFillPhoneFill } from "react-icons/bs";
import { MdEmail, MdFacebook, MdLocationPin } from "react-icons/md";
import { GiBrain } from "react-icons/gi";
import ContentContainer from "../layout/ContentContainer";

function Contact() {
  return (
    <ContentContainer className="contact__background">
      <h2 className="contact__heading">
        Pokud vám vaše srdce říká, že mě máte kontaktovat, využijte telefon,
        nebo email.
      </h2>
      {/* <div className="contact__main-image"></div> */}
      <div className="contact__content">
        <div className="contact__personal">
          <h3 className="contact__personal-name">Zuzana Jasníková</h3>
          <span className="contact__personal-contact">
            <BsFillPhoneFill className="contact__icon" />
            <a href="tel:+420727968287">727 968 287</a>
          </span>
          <span className="contact__personal-contact">
            <MdEmail className="contact__icon" />
            <a href="mailto:zuzanajasnikova@gmail.com">
              zuzanajasnikova@gmail.com
            </a>
          </span>
          <span className="contact__personal-contact">
            <MdEmail className="contact__icon" />
            <a href="mailto:zuzka.jasnikova@socion.cz">
              zuzka.jasnikova@socion.cz
            </a>
          </span>
        </div>
        <div className="contact__links">
          <h3 className="contact__links-heading">Odkazy</h3>
          <span className="contact__links-contact">
            <MdFacebook className="contact__icon" />
            <a
              href="https://www.facebook.com/zuza.jasnikova"
              target="_blank"
              rel="noreferrer"
            >
              Zuzana Jasníková
            </a>
          </span>
          <span className="contact__links-contact">
            <MdFacebook className="contact__icon" />
            <a
              href="https://www.facebook.com/profile.php?id=100086978603353"
              target="_blank"
              rel="noreferrer"
            >
              Vědomá Opava
            </a>
          </span>
          <span className="contact__links-contact">
            <MdFacebook className="contact__icon" />
            <a
              href="https://www.facebook.com/profile.php?id=100088991314026"
              target="_blank"
              rel="noreferrer"
            >
              Socion Opava a okolí
            </a>
          </span>
          <span className="contact__links-contact">
            <GiBrain className="contact__icon" />
            <a href="https://www.socion.cz/" target="_blank" rel="noreferrer">
              Socion
            </a>
          </span>
          <span
            className="contact__links-contact"
            target="_blank"
            rel="noreferrer"
          >
            <MdLocationPin className="contact__icon" />
            <a
              href="https://www.jogovyatelierluna.cz/"
              target="_blank"
              rel="noreferrer"
            >
              Kde konzultace probíhají
            </a>
          </span>
          <span className="contact__id">IČO: 87355540</span>
        </div>
      </div>
      <div className="contact-credit">
        Photo by{" "}
        <a href="https://unsplash.com/@priscilladupreez?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
          Priscilla Du Preez
        </a>{" "}
        on{" "}
        <a href="https://unsplash.com/photos/aPa843frIzI?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
          Unsplash
        </a>
      </div>
    </ContentContainer>
  );
}

export default Contact;
