import React from 'react'
import ContentContainer from '../layout/ContentContainer'

export default function AboutMe() {
  return (
    <ContentContainer>
      <div className='aboutme__main-image'></div>
      <div className='aboutme'>
        <div className='aboutme__heading'>
          <h2>O mně</h2>
        </div>
        <div className='aboutme__story-paragraphs'>
          <p className='aboutme__story-paragraph'>
            Poradila jsem si se svým ne zrovna lehkým osudem a vím, že to jde.
            Jde mít čisté vztahy, vychovávat děti s láskou a zároveň s
            hranicemi, mít fyzické zdraví a cítit naplněnost a radost ze života.
          </p>
          <p className='aboutme__story-paragraph'>
            Jsem máma čtyř dětí a s příchodem mojí první dcery jsem
            bezprostředně po jejím narození v sobě ucítila obrovskou sílu a
            znovu se objevilo moje ztracené sebevědomí. Odstartovala se tím moje
            cesta k sebepoznání, která nikdy nekončí. Můj život přes všechny
            jeho strasti, anebo právě díky nim, nazývám pestrým, obohacujícím a
            miluji ho. Miluji ho o to více, protože jsem se ho několikrát učila
            znova milovat v každé jeho etapě.
          </p>
          <p className='aboutme__story-paragraph'>
            Sama jsem si jako dítě, i jako dospělá prošla několika rozpady
            rodiny a nebyla to vůbec náhoda, že jsem byla jejich součástí a
            nasbírala jsem díky tomu v oblasti vztahů velmi cenné zkušenosti.
            Mám schopnost vidět do příčiny, což někteří lidé kolem mne v
            minulosti odmítali, jiní vítali.
          </p>
          <p className='aboutme__story-paragraph'>
            Dnes vím přesně, proč tomu tak bylo. Ti co ji odmítali, postrádali
            touhu po osobním růstu a těžko snášeli slyšet pravdu o sobě. Ti co
            ji přijímali, byli připraveni ke změně a byli obohaceni sebereflexí.
            Ti druzí jsou lidé stejní jako já a vzájemně nás to k sobě
            přitahuje.
          </p>
          <p className='aboutme__story-paragraph'>
            <strong>
              Pokud jste na mých stránkách a čtete tyto řádky, protože něco
              hledáte, zaměřte v tuto chvíli svoji mysl jen do svého srdce,
              nemyslete na nic jiného a chvíli jen tak buďte v něm. Ono vám za
              chvíli, anebo možná až za pár dní samo odpoví, zda mám být právě
              já jedním z pomocníků ve vašem životě a v cestě k sebepoznání.
            </strong>
          </p>
          <p className='aboutme__story-paragraph'>
            Jsem certifikovaný konzultant a kromě zjištění vašeho socionického
            typu osobnosti umím najít příčinu která vám brání roztáhnout křídla
            v rodině, v práci, v životě…
          </p>
          <p className='aboutme__story-statement'>
            ...Vše, co mě v životě potkalo, nebylo ničím jiným, než velkým Božím
            požehnáním...
          </p>
          <p className='aboutme__story-paragraph'>
            Na střední zemědělskou školu jsem šla kvůli lásce ke zvířatům, ale
            tam nás ji neučili, spíš naopak. Zvířata miluji dodnes, ale od té
            doby, co jsem se stala ve 24 letech mámou je mým velkým koníčkem
            psychologie, celostní medicína, duchovní příčiny nemocí,
            alternativní metody uzdravení, seberozvoj, výchova dětí a duchovní
            nauky.
          </p>
          <p className='aboutme__story-paragraph-courses'>
            Absolvované kurzy:
            <span>Asistent pedagoga</span>
            <span>Technika kvantového dotyku metrixu</span>
            <span>Certifikovaný konzultant socionických typů</span>
          </p>
        </div>
      </div>
    </ContentContainer>
  )
}
