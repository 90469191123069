// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB77Y5f5LFX7cUeVSeMPLmngLY-bqGP7I4',
  authDomain: 'blog-zuza.firebaseapp.com',
  projectId: 'blog-zuza',
  storageBucket: 'blog-zuza.appspot.com',
  messagingSenderId: '304390063464',
  appId: '1:304390063464:web:4aa7aa507856decc7402dd',
  measurementId: 'G-B0NQZEBJJS',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');

export const db = getFirestore(app);
