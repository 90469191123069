import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { collection, getDocs, query, orderBy } from 'firebase/firestore'
import { db } from '../firebase.config'

import ContentContainer from '../layout/ContentContainer'

function Blog() {
  const [blogPosts, setBlogPosts] = useState()

  const navigate = useNavigate()

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // Get reference
        const postsRef = collection(db, 'posts')

        // Create a query
        const q = query(postsRef, orderBy('timeStamp', 'desc'))

        // Execute query
        const querySnap = await getDocs(q)

        const posts = []

        querySnap.forEach(doc => {
          return posts.push({
            id: doc.id,
            data: doc.data(),
          })
        })

        setBlogPosts(posts)
      } catch (error) {
        console.log(error)
      }
    }
    fetchPosts()
  }, [])

  return (
    <ContentContainer>
      <div className='blog'>
        <div className='blog__thumbnails'>
          {blogPosts &&
            blogPosts.map(({ id, data: post }) => {
              return (
                <div
                  className='blog__thumbnail'
                  key={id}
                  onClick={() => navigate(`/blog/${id}`)}
                >
                  <div
                    className='blog__thumbnail-image'
                    style={{ backgroundImage: `url(${post.imgUrl})` }}
                  ></div>
                  <span className='blog__thumbnail-title'>
                    <h4 className='blog__thumbnail-heading'>{post.title}</h4>
                  </span>
                </div>
              )
            })}
        </div>
      </div>
    </ContentContainer>
  )
}

export default Blog
