import React from 'react';

function ContentContainer(props) {
  return (
    <div className={`content-container ${props.className}`}>
      {props.children}
    </div>
  );
}

export default ContentContainer;
